import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import Container from "../Container";
import styles from "./Article.module.css";

const Article = ({ className, children }) => {
  return (
    <Container
      maxWidth={768}
      className={classnames(styles.containerOverride, styles.root, className)}
    >
      {children}
    </Container>
  );
};

Article.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Article.defaultProps = {
  className: undefined,
};

export default Article;
