import { Node } from "djedi-react";
import React from "react";

import Container from "#components/Container";
import Section from "#components/Section";
import Standard, {
  CONTAINER_MAX_WIDTH,
} from "#pages-helpers/contentPages/standard";

const NODES = {
  TITLE: <Node uri="mittmtrx/title">TITLE</Node>,
  DESCRIPTION: <Node uri="mittmtrx/metaDescription"></Node>,
  HEADING: <Node uri="mittmtrx/heading">HEADING</Node>,
  PREAMBLE: <Node uri="mittmtrx/preamble.md">PREAMBLE</Node>,
  HERO: <Node uri="mittmtrx/hero.img" />,
  IMG: <Node uri="mittmtrx/image.img" />,
  CONTENT: <Node uri="mittmtrx/main.md">CONTENT</Node>,
  CTA_TEXT: <Node uri="mittmtrx/cta/text"></Node>,
  CTA_URL: <Node uri="mittmtrx/cta/url"></Node>,
};

export default function Page() {
  return (
    <Standard
      {...NODES}
      CONTENT={NODES.CONTENT}
      CTA_TEXT={NODES.CTA_TEXT}
      CTA_URL={NODES.CTA_URL}
      after={
        <Section padding="regular">
          <Container maxWidth={CONTAINER_MAX_WIDTH}>{NODES.IMG}</Container>
        </Section>
      }
    />
  );
}
