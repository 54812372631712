import { ForceNodes } from "djedi-react";
import PropTypes from "prop-types";
import React from "react";

import Article from "#components/Article";
import Button from "#components/Button";
import Container from "#components/Container";
import Heading from "#components/Heading";
import Hero from "#components/Hero";
import Layout from "#components/Layout";
import Section from "#components/Section";
import Text from "#components/Text";

export const CONTAINER_MAX_WIDTH = 768;

const Page = ({
  TITLE,
  HERO,
  HEADING,
  PREAMBLE,
  DESCRIPTION,
  CONTENT,
  CTA_URL,
  CTA_TEXT,
  children,
  after,
}) => {
  return (
    <Layout title={TITLE} description={DESCRIPTION}>
      {HERO && <Hero imageNode={HERO} />}
      <Article>
        <Heading tag="h1" variant="title1" color="textPrimary">
          {HEADING}
        </Heading>
        <Text variant="paragraph18" weight="regular">
          {PREAMBLE}
        </Text>
      </Article>
      {children}
      <Article>
        <Text variant="paragraph16" weight="regular">
          {CONTENT}
        </Text>
      </Article>
      {React.cloneElement(CTA_URL, {
        render: function render(state) {
          if (state.type === "success") {
            const url = state?.content?.props?.children;
            return url ? (
              <Section padding="regular">
                <Container maxWidth={CONTAINER_MAX_WIDTH}>
                  <Button href={state?.content?.props?.children}>
                    {CTA_TEXT}
                  </Button>
                </Container>
              </Section>
            ) : null;
          }

          return null;
        },
      })}
      {after}
      <ForceNodes>
        {TITLE}
        {DESCRIPTION}
        {CTA_TEXT}
        {CTA_URL}
      </ForceNodes>
    </Layout>
  );
};

Page.propTypes = {
  after: PropTypes.node,
  children: PropTypes.node,
  TITLE: PropTypes.node.isRequired,
  HEADING: PropTypes.node.isRequired,
  PREAMBLE: PropTypes.node.isRequired,
  DESCRIPTION: PropTypes.node,
  HERO: PropTypes.node,
  CONTENT: PropTypes.node.isRequired,
  CTA_TEXT: PropTypes.node.isRequired,
  CTA_URL: PropTypes.node.isRequired,
};

Page.defaultProps = {
  children: null,
  after: null,
  DESCRIPTION: undefined,
  HERO: undefined,
};

export default Page;
